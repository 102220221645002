import React, { createContext, useContext, useState, useEffect } from "react";

const SubAdminContext = createContext();

export const SubAdminProvider = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [SubAdmin, setSubAdmin] = useState({
    id: "",
    name: "",
    mobilenumber: "",
    email: "",
    role: "",
    flcty_id: "",
    type: "",

    //   clinic details
    clinicName: "",
    clinicType: "",
    clinicPhone: "",
    clinicEmail: "",
    clinicImg: "",
    clinicAddress: "",
  });

 useEffect(()=>{
  getUserInfo();
 },[])
  const getUserInfo = async () => {};

  return (
    <SubAdminContext.Provider value={{ SubAdmin, setSubAdmin }}>
      {children}
    </SubAdminContext.Provider>
  );
};

// Hook to use SubAdmin context
export const useSubAdmin = () => useContext(SubAdminContext);

