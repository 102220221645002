import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo/logo.png";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateForward } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { useSubAdmin } from "../subadmin/context/SubAdminContext";
import apiService from "../api/apiService";

const LoginWithOtp = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [time, setTime] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [phone_number, setNumber] = useState("");
  const [generated_otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setSubAdmin } = useSubAdmin();
  const [error, setError] = useState("");
  // Handle phone number change
  const handleNumberChange = (e) => {
    const value = e.target.value;
  
    // Allow only numeric values
    if (!/^\d+$/.test(value) && value !== "") {
      setError("Phone number must be numeric");
    } else if (value.length !== 10) {
      setError("Phone number must be 10 digits");
    } else {
      setError("");
    }
  
    setNumber(value);  // Update the phone number state
  };
  // Handle OTP change
  const handleOtpChange = (e) => {
    const value = e.target.value;
  
    // Allow only numeric values
    if (!/^\d+$/.test(value) && value !== "") {
      setError("OTP must be numeric");
    } else if (value.length !== 6) {
      setError("OTP must be 6 digits");
    } else {
      setError("");
    }
  
    setOtp(value);
  };

  // Handle form submission for sending OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     
      const res = await apiService.sentLoginotp({
          mobilenumber: phone_number,
          userType: "Admin", // Send only Admin user type
        });
      if (res.status === 200) {
        setTimerActive(true);
        // toast.success("OTP sent successfully!");
        Swal.fire({
          icon: "success",
          title: "OTP sent successfully!",
          text: "Please enter the OTP sent to your mobile number.",
          icon: "success",
          timer: 2000,
          showConfirmButton: false, 
        })
        setOtpSent(true);
      }
    } catch (error) {
      console.log(error);

      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message ||  error?.response?.data?.response?.message,
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  // Handle OTP verification
  const handleOTPVerification = async (e) => {
    e.preventDefault();
    try {

      const res = await apiService.verifyLoginOtp( {
        mobilenumber: phone_number,
        otpNo: generated_otp,
        userType: "Admin", // Send only Admin user type
        loginType: "Otp",
      })

      if (res.status === 200) {
        const userDetails = res.data.userData;
        setSubAdmin({
          id: userDetails.id,
          mobilenumber: userDetails.mobilenumber,
          email: userDetails.email,
          flcty_id: userDetails.fclty_id,
          type: userDetails.type,
             //  clinic details 
             clinicName: userDetails.facility_details.fclty_nm,
             clinicType: userDetails.facility_details.fclty_type,
             clinicPhone: userDetails.facility_details.fclty_cell_num,
             clinicEmail: userDetails.facility_details.fclty_email,
             clinicAddress: userDetails.facility_details.fclty_address,
             clinicImg: userDetails.facility_details.fclty_image,
        });
        sessionStorage.setItem("subadminToken", userDetails.token);
        navigate("/doctormanagementsubadmin");
      }
      if (res.responseCode == 400) {
        Swal.fire({
          icon: "warning",
          text: res?.data.message || "Number not found in database",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message || "Error verifying OTP",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  // Resend OTP
  const resendOTP = async () => {
    if (!timerActive) {
      try {
         const res = await apiService.sentLoginotp({
          mobilenumber: phone_number,
          userType: "Admin", // Send only Admin user type
        });
        if (res.status === 200) {
          setTimerActive(true);
          // toast.success("OTP sent successfully!");
          Swal.fire({
            icon: "success",
            title: "OTP sent successfully!",
            text: "Please enter the OTP sent to your mobile number.",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,  // Hide the confirm button
          })
          setOtpSent(true);
          setOtp("")
        }
      } catch (error) {
        console.log(error);
  
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message ||  error?.response?.data?.response?.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
      
  };

  // Timer for OTP resend
  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        if (time > 0) {
          setTime((prevTime) => prevTime - 1);
        } else {
          setTimerActive(false);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timerActive, time]);

  // Redirect to password login
  const handlePasswordLogin = () => {
    navigate("/login/password");
  };

  return (
    <>
      <div className="container1" style={{backgroundColor:"#db9194"}}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="row login-box shadow rounded" style={{ minWidth: "50%", minHeight:"350px"}}>
            <div
              className="col-md-6 align-content-center login-col-1 text-center"
              // id="background"
              style={{backgroundColor:"#fff"}}
            >
              <img
                src={logo}
                className="logo-img"
                alt="web logo"
                height={100}
                width={150}
              />
            </div>
            <div className="col-md-6 p-4 bg-body-tertiary d-flex align-items-center">
              <div className="">
                <p className="text-center fs-4 fw-medium">
                  {otpSent
                    ? "Sign in to your Account"
                    : "Login to your Account"}
                </p>
                {otpSent ? (
                  <form className="pt-3" onSubmit={handleOTPVerification}>
                    <TextField
                      type="text"
                      required
                      error={error}
                      label="Enter OTP Verification"
                      value={generated_otp}
                      onChange={handleOtpChange}
                      fullWidth
                      size="small"
                    />
                      <Typography variant="p" color="error" sx={{ fontSize:"10px"}} >{error}</Typography>
                    <div className="mt-3 pb-2 d-flex justify-content-between">
                      <p
                        className="text-main-color"
                        onClick={resendOTP}
                        disabled={timerActive}
                      >
                        Resend OTP{" "}
                        <FontAwesomeIcon icon={faArrowRotateForward} />
                      </p>
                      <p>{time}</p>
                    </div>
                    <Box pt={3}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="success"
                      >
                        Verify OTP
                      </Button>
                    </Box>
                  </form>
                ) : (
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      type="number"
                      value={phone_number}
                      required
                      error={error}
                      onChange={handleNumberChange}
                      size="small"
                      variant="outlined"
                    />
                    <Typography variant="p" color="error" sx={{ fontSize:"10px"}} >{error}</Typography>
                    <Box pt={3}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Sign In
                      </Button>
                    </Box>
                  </form>
                )}
              </div>
              {/* <div className="pt-3 text-center">
                <p>_____________or____________</p>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handlePasswordLogin}
                >
                  Sign in with Password
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      <ToastContainer position="top-center" />
      </div>
    </>
  );
};

export default LoginWithOtp;
