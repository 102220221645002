import React, { useEffect, useState } from "react";
import logo from "../assets/logo/logo.png";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  TextField,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSubAdmin } from "../subadmin/context/SubAdminContext";

const LoginWithPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { SubAdmin, setSubAdmin } = useSubAdmin();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  
  // Handle form submission
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Login as sub-admin
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL_EHR}/polyclinicAdminLogin`,
        {
          email: data.mobilenumber,
          password: data.password,
        }
      );

      // Handle successful response
      if (res?.status === 200) {
        const userDetails = res.data.data.data;
        const token = res.data.data.tokens.accessToken;
        sessionStorage.setItem("subadminToken", token);

        setSubAdmin({
          id: userDetails.id,
          mobilenumber: userDetails.mobilenumber,
          email: userDetails.email,
          flcty_id: userDetails.fclty_id,
          type: userDetails.type,
        });

        navigate("/doctormanagementsubadmin");
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.response?.message || error.message;
      toast.error(`Error: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center rounded" style={{ minHeight: "90vh" }}>
        <div className="p-4">
        <div className="row login-box shadow rounded" style={{minWidth:"50%"}}>
          <div className="col-md-6 align-content-center text-center" id="background">
            <img
              src={logo}
              className=" "
              alt="web logo"
              height={100}
              width={200}
            />
          </div>
          <div className="col-md-6  bg-body-tertiary">
            <p className="text-center fs-4 fw-medium">Login to your Account</p>
            <form onSubmit={handleSubmit(onSubmit)} className="pt-2">
              {/* Mobile Number or Email */}
              <TextField
                size="small"
                fullWidth
                label="Phone No"
                type="text"
                {...register("mobilenumber", {
                  required: "Phone is required",
                })}
                margin="normal"
              />
              {errors.mobilenumber && (
                <small className="text-danger">{errors.mobilenumber.message}</small>
              )}

              {/* Password Field */}
              <FormControl fullWidth sx={{ mb: 1 }} variant="outlined">
                <InputLabel size="small" htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  size="small"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  {...register("password", {
                    required: "Password is required",
                  })}
                  endAdornment={
                    <InputAdornment size="small" position="end">
                      <IconButton
                      size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <FontAwesomeIcon className="fs-5" icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon className="fs-5" icon={faEye} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {errors.password && (
                  <small className="text-danger">{errors.password.message}</small>
                )}
              </FormControl>

              {/* Submit Button */}
              <Button type="submit" variant="contained" fullWidth disabled={loading}>
                Sign In
              </Button>
            </form>

            {/* Loading Spinner */}
            {loading && (
              <div className="d-flex justify-content-center pt-2">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}

            {/* Alternative Sign-in Option */}
            <div className="pt-3">
              <p className="text-center">______________ or ______________</p>
              <Button fullWidth type="button" variant="contained" onClick={() => navigate("/")}>
                Sign in with OTP
              </Button>
            </div>
          </div>
        </div>
        </div>
      </div>
      {/* Toast Notifications */}

      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default LoginWithPassword;
